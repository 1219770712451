import Link from "next/link";
//import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import Image from 'next/image';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';


const Hero = ({carouselItems, movilImage})=> {
  //const router = useRouter();
  const [banners, setBanners] = useState(carouselItems? carouselItems :false)
  const [movil, setMovil] = useState(movilImage? movilImage: false)
  const [showMovil, setShowMovil] = useState(true)

  return (
	<React.Fragment>
		
		<section className="hero position-relative">
			<Swiper
				slidesPerView={1}
				rewind={true}
				autoplay={{
					delay: 4500,
					disableOnInteraction: false,
				}}
				modules={[Autoplay]}
			>
				{
					banners ?
						banners.map((item,index) => {
							let descriptionState

							if ( typeof item.Description === "string" ) {
								if ( item.Description.length > 0) {
									descriptionState = true
								} else {
									descriptionState = false
								}
							} else if (typeof item.Description === null) {
								descriptionState = false
							}

							return(
								
								<SwiperSlide className='item-carousel'>
								
									{
										!descriptionState &&
											<div className="hero-description">
												<h2 className="title-mobile hero-title">{item.Title}</h2>
												{
													
													Boolean(item.Button_Text && item.Button_URL) &&
													<div>
														<Link className="a-button" href={'/'+item.Button_URL} >
															{item.Button_Text}
														</Link>
													</div>
												}
											</div>
									}
									{
										descriptionState &&
											<div className="hero-description">
												<h2 className="hero-title">{item.Title}</h2>
												{
													Boolean(item.Button_Text && item.Button_URL) &&
													<div>
														<Link href={'/'+item.Button_URL} className="a-button" >
															{item.Button_Text}
														</Link>
													</div>
												}
												<div style={{color: "#ffffff", fontSize: "20px", textAlign: "Center", maxWidth: "80%", margin: "0 auto"}}>
													{item.Description}
												</div>
											</div>
									}
									
									
									{
										item.Background_Video && item.Background_Image &&
											<div className="hero-desktop">
												<video
													alt={item.title}
													src={item.Background_Video.url}
													poster={item.Background_Image.url.replace(/upload\/[a-z0-9]*\/yachting-/gm, 'upload/w_1200/yachting-')}
													muted
													loop
													autoPlay
													preload="metadata"
													
												>
													
												</video>
											</div>
									}
									{
										!item.Background_Video && item.Background_Image && 
										<div className="hero-desktop">
											<Image
												alt={item.title}
												src={item.Background_Image.url.replace(/upload\/[a-z0-9]*\/yachting-/gm, 'upload/w_1200/yachting-')}
												fill = { true }
											/>
										</div>
									}
									
									{
										movil && showMovil && 
										<div className="hero-movil">
											<Image
												className="heroImage"
												src={movil.url.replace(/upload\/[a-z0-9]*\/yachting-/gm, 'upload/w_600/yachting-')}
												fill = { true }
											/> 
										</div>
										
									}
								
								</SwiperSlide>
							)
						})
					: null
				}
			</Swiper>
		</section>
	</React.Fragment>
	
  );
};

export default Hero;
