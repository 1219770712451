import dynamic from "next/dynamic"
import Layout from "../components/layout";

import Head from 'next/head';

import { request } from "graphql-request";
import {
  GET_HOME_BANNERS,
  GET_MENU_YACHTS,
  GET_HOME_NEWSLETTER,
  GET_HOME_CTA,
  GET_HOME_FAQ,
  GET_HOME_PARTNERS,
  GET_HOME_BRANDS
} from "../lib/queries";
import Hero from '../components/home/Hero';


const Newsletter = dynamic(() => import("../components/Newsletter").then((module) =>module.default),{ssr: false});

const SearchBar = dynamic(() => import('../components/SearchBar').then((module) => module.default),
{ssr: false} );
const Inventory = dynamic(() => import('../components/home/Inventory').then((module) => module.default),
{ssr: false} );
const Cta = dynamic(() => import('../components/home/Cta').then((module) => module.default),
{ssr: false} );
const Brands = dynamic(() => import('../components/home/Brands').then((module) => module.default),
{ssr: false} );
const Partners = dynamic(() => import('../components/home/Partners').then((module) => module.default),
{ssr: false} );
const News = dynamic(() => import('../components/home/News').then((module) => module.default),
{ssr: false} );
const FAQ = dynamic(() => import('../components/home/FAQ').then((module) => module.default),
{ssr: false} );
const NewsletterFooter = dynamic(() => import('../components/NewsletterFooter').then((module) => module.default),
{ssr: false} );

function Home({banners, bannerMovil, navbar, newsletter, cta, faq, partners, brands }) {
  return (
    <Layout navbarColor="white" header={navbar}>
      <Head>
        <title>Yachting México </title>
        <meta name="description" content="The Home Of Luxury Yachts"/>
      </Head>
      
      <main>
        <Newsletter data={newsletter}/>
        <div style={{marginTop: "110px"}}>
            <Hero carouselItems={banners} movilImage={bannerMovil}/>
        </div>
        
        <SearchBar/>
        
        <Inventory/>
        <Cta data={cta} />
        <Brands brandList={brands.homepage.brands} />

        <Partners titles={partners.homepage.partners.title} partners={partners.homepage.partners.partner} titleStyles={partners.homepage.partners.titleStyle}  />
        <News/>
        <FAQ data={faq} />
        <NewsletterFooter data={newsletter} />
      </main>
      
    </Layout>
  );
}
export async function getStaticProps(context) { 
  try{
    const { locale } = context;
    const banner = await request(
      process.env.STRAPI_GRAPHQL_ENDPOINT,
      GET_HOME_BANNERS,
      { locale: locale },
    );
    const { yachts }  = await request(
      process.env.STRAPI_GRAPHQL_ENDPOINT,
      GET_MENU_YACHTS,
      { locale: locale },
    )
    const newsletter  = await request(
      process.env.STRAPI_GRAPHQL_ENDPOINT,
      GET_HOME_NEWSLETTER,
      { locale: locale },
    )
    const cta = await request(
      process.env.STRAPI_GRAPHQL_ENDPOINT,
      GET_HOME_CTA,
      { locale: locale },
    )
    const faq = await request(
      process.env.STRAPI_GRAPHQL_ENDPOINT,
      GET_HOME_FAQ,
      { locale: locale },
    )
    const partners = await request(
      process.env.STRAPI_GRAPHQL_ENDPOINT,
      GET_HOME_PARTNERS,
      { locale: locale },
    )
    const brands = await request(
      process.env.STRAPI_GRAPHQL_ENDPOINT,
      GET_HOME_BRANDS,
      { locale: locale },
    )
    
    return {
      props: { 
        banners: banner.homepage.hero.Carousel, 
        bannerMovil: banner.homepage.hero.extraImage,
        navbar: yachts,
        newsletter: newsletter,
        cta: cta.homepage.cta,
        faq: faq.homepage.faq,
        partners: partners,
        brands: brands
  
       },
       revalidate: 3600
    };

  }catch(e){
    console.log(e)
    return {
      notFound: true,
    };
  }
 
}

export default Home;
